/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttachmentTile, Text, TextField } from '@dsny/dsny-component-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { inputHandler } from 'src/utils/InputHandler';
import {
  EditSongDescWrapper,
  SongInfoWrapper,
  TextFieldWrapper,
  UploadedFileWrapper,
} from './EditSongInfo.styles';
import { AddTrackObj } from '../MultiAsset.slice';
import StartTrackingSelection from '../StartTrackingSelection/StartTrackingSelection';

const ARTIST_FIELD = 'artist';
const TITLE_FIELD = 'title';
const START_DATE_FIELD = 'startDate';

const schema = yup.object().shape({
  artist: yup.string().required(),
  title: yup.string().required(),
});

type EditSongInfoProps = {
  tracks: AddTrackObj[];
  handleTitle: (title: string) => void;
  handleArtist: (title: string) => void;
  handleStartDate: (date: Date) => void;
  updateStartDate: boolean;
  updateArtistName: boolean;
};

const EditSongInfo: React.FC<EditSongInfoProps> = ({
  tracks,
  handleArtist,
  handleTitle,
  handleStartDate,
  updateStartDate,
  updateArtistName,
}) => {
  const { t } = useTranslation();
  const [selectedTracks, setSelectedTracks] = useState<AddTrackObj[]>([]);
  const [activeTracks, setActiveTracks] = useState<AddTrackObj[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedTracksSize, setSelectedTracksSize] = useState<number>(0);
  const [selectedDateTracksSize, setSelectedDateTracksSize] =
    useState<number>(0);

  // Banner error message
  const errorMessages = {
    artist: t('MULTI_ASSET_ERROR_ARTIST_NAME_FIELD'),
    title: t('MULTI_ASSET_ERROR_SONG_TITLE_FIELD'),
  };

  // Form validation
  const { register, setValue, getValues, trigger } = useForm({
    mode: 'all',
    resolver: yupResolver<any>(schema),
    shouldUnregister: true,
    defaultValues: {
      [ARTIST_FIELD]: '',
      [TITLE_FIELD]: '',
      [START_DATE_FIELD]: '',
    },
  });

  // Clear start tracking date field when more then one track is selected
  const handlingStartDateInputUponSelection = (
    startDate: Date | string,
    hasUpdateStartDate: boolean
  ) => {
    if (
      selectedTracks.length > 1 &&
      selectedTracks.length !== selectedDateTracksSize
    ) {
      setSelectedDateTracksSize(selectedTracks.length);
      setValue(START_DATE_FIELD, '');
    } else if (hasUpdateStartDate) {
      setValue(START_DATE_FIELD, startDate || new Date());
    }
    if (selectedTracks.length <= 1) {
      setSelectedDateTracksSize(selectedTracks.length);
      setValue(START_DATE_FIELD, startDate || new Date());
    }
  };

  // Clear artist input field when more then one track is selected
  const handlingArtistInputUponSelection = (
    artist: string,
    hasUpdateArtistName: boolean
  ) => {
    if (
      selectedTracks.length > 1 &&
      selectedTracks.length !== selectedTracksSize
    ) {
      setSelectedTracksSize(selectedTracks.length);
      setValue(ARTIST_FIELD, '');
    } else if (hasUpdateArtistName) {
      setValue(ARTIST_FIELD, artist || '');
    }
    if (selectedTracks.length <= 1) {
      setSelectedTracksSize(selectedTracks.length);
      setValue(ARTIST_FIELD, artist || '');
    }
  };

  useEffect(() => {
    const title = selectedTracks.length > 0 ? '' : activeTracks[0]?.title || '';
    const artist = activeTracks[0]?.artist || '';
    const startDate = activeTracks[0]?.startTrackingDate || '';

    setRefresh(!refresh);
    setValue(TITLE_FIELD, title || '');

    handlingStartDateInputUponSelection(startDate, updateStartDate);
    handlingArtistInputUponSelection(artist, updateArtistName);
  }, [activeTracks, updateStartDate, updateArtistName]);

  useEffect(() => {
    setActiveTracks(
      tracks.filter((track) => track.isSelected || track.isActive)
    );

    setSelectedTracks(tracks.filter((track) => track.isSelected));
  }, [tracks]);

  // Handling error message
  const hasError = (fieldId: string) => {
    if (
      activeTracks.filter((track) => track.isFileUploadError).length > 0 &&
      getValues(fieldId).trim() === ''
    ) {
      return errorMessages[fieldId as keyof typeof errorMessages];
    }
    return false;
  };

  return (
    <>
      {/* Edit Song Information */}
      <EditSongDescWrapper>
        <Text fontSize="14px" fontWeight={700} color={theme.colors.neutralW20}>
          {t('MULTI_EDIT_SONG_INFORMATION')}
        </Text>
        <Text
          ellipsis
          fontSize="12px"
          fontWeight={400}
          color={theme.colors.neutralW20}
        >
          <strong>{activeTracks.length}</strong>&nbsp;
          {t('MULTI_SONGS_SELECTED_FOR_BATCH_EDITING')}
        </Text>
      </EditSongDescWrapper>

      <SongInfoWrapper>
        <TextFieldWrapper>
          {/* Artist name */}
          <TextField
            id={ARTIST_FIELD}
            setting="mtr"
            labelText={t('MULTI_ASSET_ARTIST_NAME')}
            type="text"
            required
            readOnly={false}
            value={getValues(ARTIST_FIELD)}
            {...register(ARTIST_FIELD)}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleArtist(getValues(ARTIST_FIELD));
            }}
            error={hasError(ARTIST_FIELD)}
            wrapperStyles={{
              paddingRight: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
            }}
            labelStyle={{ fontSize: '14px' }}
            errorStyle={{ marginTop: '8px' }}
            ref={null}
          />

          {/* Song title */}
          <TextField
            id={TITLE_FIELD}
            setting="mtr"
            labelText={t('MULTI_ASSET_SONG_TITLE')}
            type="text"
            required
            readOnly={selectedTracks.length > 0}
            value={getValues(TITLE_FIELD)}
            {...register(TITLE_FIELD)}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleTitle(getValues(TITLE_FIELD));
            }}
            error={activeTracks.length <= 1 && hasError(TITLE_FIELD)}
            wrapperStyles={{
              paddingRight: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
            }}
            labelStyle={{ fontSize: '14px' }}
            errorStyle={{ marginTop: '8px' }}
            ref={null}
          />
        </TextFieldWrapper>

        {/* Start tracking date - Day Picker */}
        <StartTrackingSelection
          handleStartDate={handleStartDate}
          displayDate={getValues(START_DATE_FIELD)}
        />

        {/* Uploaded file list */}
        {activeTracks.map((track) => {
          return (
            <UploadedFileWrapper key={track.S3File.key}>
              <AttachmentTile
                attachment={{
                  id: '',
                  uploaduuid: '',
                  title: track?.file?.name || '',
                  filename: track?.file?.name || '',
                  size: track?.file?.size || 0,
                  type: track?.file?.type || '',
                  upload_s3_key: undefined,
                  attachid: undefined,
                }}
                onDelete={() => {
                  throw new Error('Function not implemented.');
                }}
                setting="neutral"
                displayActionIcon={false}
                isUploading={false}
              />
            </UploadedFileWrapper>
          );
        })}
      </SongInfoWrapper>
    </>
  );
};

export default EditSongInfo;
