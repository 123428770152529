/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  Icon,
  MaterialIcon,
  PaymentIcon,
  SpinningIcon,
  Text,
  TextField,
} from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';
import theme from 'src/styles/theme';
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { IconNames } from '@dsny/dsny-component-library/dist/components/PaymentIcon/PaymentIcon';
import { makeFirstLetterUpperCase } from 'src/utils/Formatter';
import { postPurchasePrepare } from './AddPayment.thunks';
import {
  ClickableStep,
  Container,
  ErrorWrapper,
  FlexButtonWrapper,
  FormButton,
  PurchaseWrapper,
  StepsWrapper,
} from '../Purchase.styles';
import {
  CardDetailsWrapper,
  CardExpirySection,
  CardIconWrapper,
  CardInfo,
  CheckoutBox,
  EditCardWrapper,
  FinishReviewButton,
  InlineMessage,
  Label,
  StripeElementWrapper,
  StripeInputStyle,
  SubTitleOneWrapper,
  SubTitleTwoWrapper,
  Title,
} from './AddPayment.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import { resetStateAddPayment } from './AddPayment.slice';
import { AddTrackList } from '../MultiAsset';

interface ErrorMessages {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
}

interface FocusStripe {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

// Stripe input style
const inputStyle = {
  iconColor: theme.colors.neutralW40,
  color: theme.colors.neutralW40,
  fontWeight: '400',
  fontSize: '14px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: theme.colors.neutralW40,
  },
  '::placeholder': {
    color: theme.colors.neutralW70,
  },
};

// Stripe error style
const errorStyle = {
  iconColor: theme.colors.neutralW40,
  color: theme.colors.neutralW40,
};

const StripeCheckoutForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { addPayment, selectPackage } = useSelector((state: RootState) => ({
    addPayment: state.addPayment,
    selectPackage: state.selectPackage,
  }));
  const { currentPaymentMethod, paymentPrepare, hasError } = addPayment;
  const [showCancel, setShowCancel] = useState(false);
  const [cardholderError, setCardholderError] = useState(false);
  const [cardholder, setCardholder] = useState('');
  const [isCardSaved, setCardSaved] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [stripeProcessingError, setStripeProcessingError] = useState('');
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });
  const [focusStripe, setFocusStripe] = useState<FocusStripe>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!tracks.length && !selectedSong) {
      navigate('/payment/multiasset');
    }

    if (!selectPackage?.selectedPackage) {
      navigate('/payment/selectpackage');
    }
  }, []);

  useEffect(() => {
    if (currentPaymentMethod.id) {
      setCardSaved(true);
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (paymentPrepare?.clientSecret && elements) {
      stripe
        ?.confirmCardSetup(paymentPrepare?.clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              name: cardholder,
            },
          },
        })
        .then((data) => {
          setTimeout(() => {
            setIsFetching(false);
            const { error } = data;

            if (error) {
              setStripeProcessingError(error.message!);
            } else {
              navigate('/payment/summary');
            }
          }, 500);
        });
    }
  }, [paymentPrepare, elements]);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false);
    }
    if (hasError) {
      setStripeProcessingError(' ');
    }
  }, [hasError]);

  // Handling stripe onFocus
  const handleFocusStripe = (event: any) => {
    const temp = focusStripe;
    temp[event.elementType as keyof FocusStripe] = true;
    setFocusStripe({ ...temp });
  };

  // Handling stripe onBlur
  const handleBlurStripe = (event: any) => {
    const temp = focusStripe;
    temp[event.elementType as keyof FocusStripe] = false;
    setFocusStripe({ ...temp });
  };

  // Handling stripe onChange
  const handleChangeStripe = (event: any) => {
    const { error } = event;
    if (error) {
      const temp = errorMessages;
      temp[event.elementType as keyof ErrorMessages] = error.message;
      setErrorMessages({ ...temp });
    } else {
      const temp = errorMessages;
      temp[event.elementType as keyof ErrorMessages] = '';
      setErrorMessages({ ...temp });
    }
  };

  // Handling cardholder onChange
  const handleChangeCardholder = (event: ChangeEvent | Event) => {
    const e = event as any;
    const { value } = e.target;
    setCardholderError(!value.length);
  };

  // Handling stripe form submit
  const handleSubmitStripe = async (e: any) => {
    if (isFetching === true) return;
    e.preventDefault();

    setIsFetching(true);

    const { currentTarget } = e;
    const fD = new FormData(currentTarget);

    const cardInfo = {
      name: fD.get('cardholder'),
    };

    if (cardInfo.name) {
      setCardholder(cardInfo.name as string);
    }

    if (!isCardSaved) {
      dispatch(postPurchasePrepare());
    } else {
      navigate('/payment/summary');
    }
  };

  // Handling edit button
  const handleEditButton = () => {
    setCardSaved(false);
    dispatch(resetStateAddPayment());
  };

  // Payment error banner
  const errorPaymentProcessing = {
    title: t('ADD_PAYMENT_ERROR_FAILED'),
    description: stripeProcessingError,
  };

  // Rendering breadcrumb
  const breadCrumb = () => {
    return (
      <StepsWrapper>
        <ClickableStep
          $isClickable
          onClick={() => navigate('/payment/selectpackage')}
        >
          <Icon
            name="ArrowLeft"
            style={{
              width: '14px',
              height: '14px',
              marginRight: '4px',
              color: theme.colors.neutralW30,
            }}
          />
          {tracks.length > 0 ? t('SIGNUP_STEP_THREE') : t('SIGNUP_STEP_TWO')}
        </ClickableStep>
        &nbsp;
        {tracks.length > 0 ? t('SIGNUP_OF_3_STEP') : t('SIGNUP_OF_2_STEP')}
      </StepsWrapper>
    );
  };

  return (
    <PurchaseWrapper>
      <Container>
        <CheckoutBox>
          {/* Banner messages */}
          {stripeProcessingError && (
            <ErrorWrapper>
              <Banner
                bannerContent={errorPaymentProcessing}
                variant="error"
                width="782"
              />
            </ErrorWrapper>
          )}
          {breadCrumb()}
          <Title>{t('ADD_PAYMENT_TITLE')}</Title>
          <SubTitleOneWrapper>
            <Text fontSize="14px" color={theme.colors.neutralW40}>
              {t('ADD_PAYMENT_TITLE_SUBTITLE_ONE')}
            </Text>
          </SubTitleOneWrapper>
          <SubTitleTwoWrapper>
            <Text fontSize="14px" color={theme.colors.neutralW40}>
              {t('ADD_PAYMENT_TITLE_SUBTITLE_TWO')}
            </Text>
          </SubTitleTwoWrapper>

          {/* Saved card number */}
          {isCardSaved && (
            <CardDetailsWrapper>
              {/* Card details */}
              <Text
                fontSize="16px"
                fontWeight={700}
                color={theme.colors.neutralW20}
                style={{ paddingBottom: '16px' }}
              >
                {t('ADD_PAYMENT_CARD_DETAILS')}
              </Text>
              <EditCardWrapper>
                <CardIconWrapper>
                  {/* Credit Card Icon */}
                  <PaymentIcon
                    name={
                      makeFirstLetterUpperCase(
                        currentPaymentMethod?.brand
                      ) as keyof typeof IconNames
                    }
                  />
                  <CardInfo>
                    **** {currentPaymentMethod.last4}
                    <p>
                      {t('SUMMARY_EXPIRY')} {currentPaymentMethod.exp_month}/
                      {currentPaymentMethod.exp_year}{' '}
                    </p>
                  </CardInfo>
                </CardIconWrapper>
                {/* Edit button */}
                <FormButton
                  setting="mtr"
                  variant="tertiary"
                  fontSize="14px"
                  type="button"
                  onClick={handleEditButton}
                  style={{
                    fontFamily: theme.fonts.primary,
                    height: '36px',
                    borderRadius: '5px',
                    alignSelf: 'center',
                  }}
                >
                  {t('PURCHASE_EDIT_BUTTON')}
                </FormButton>
              </EditCardWrapper>
            </CardDetailsWrapper>
          )}

          {/* Stripe fields */}
          <form onSubmit={handleSubmitStripe}>
            <div hidden={isCardSaved}>
              <StripeElementWrapper>
                <StripeInputStyle
                  $isInvalid={!!errorMessages.cardNumber}
                  $focus={focusStripe.cardNumber}
                >
                  <Label $isInvalid={!!errorMessages.cardNumber}>
                    {t('ADD_PAYMENT_CARD_NUMBER')}
                  </Label>
                  <CardNumberElement
                    onChange={handleChangeStripe}
                    onFocus={handleFocusStripe}
                    onBlur={handleBlurStripe}
                    className=".Error"
                    options={{
                      placeholder: t('ADD_PAYMENT_CARD_NUMBER_PLACEHOLDER'),
                      style: {
                        base: inputStyle as any,
                        invalid: errorStyle,
                      },
                    }}
                  />
                </StripeInputStyle>
                {errorMessages.cardNumber && (
                  <InlineMessage $inlineMessage={!!errorMessages.cardNumber}>
                    {errorMessages.cardNumber}
                  </InlineMessage>
                )}
              </StripeElementWrapper>

              <CardExpirySection>
                {/* Expiry date */}
                <StripeElementWrapper>
                  <StripeInputStyle
                    $isInvalid={!!errorMessages.cardExpiry}
                    $focus={focusStripe.cardExpiry}
                  >
                    <Label $isInvalid={!!errorMessages.cardExpiry}>
                      {t('ADD_PAYMENT_EXPIRY_DATE')}
                    </Label>
                    <CardExpiryElement
                      onChange={handleChangeStripe}
                      onFocus={handleFocusStripe}
                      onBlur={handleBlurStripe}
                      options={{
                        placeholder: t('ADD_PAYMENT_EXPIRY_DATE_PLACEHOLDER'),
                        style: {
                          base: inputStyle as any,
                          invalid: errorStyle,
                        },
                      }}
                    />
                  </StripeInputStyle>
                  {errorMessages.cardExpiry && (
                    <InlineMessage $inlineMessage={!!errorMessages.cardExpiry}>
                      {errorMessages.cardExpiry}
                    </InlineMessage>
                  )}
                </StripeElementWrapper>

                {/* CVC */}
                <StripeElementWrapper>
                  <StripeInputStyle
                    $isInvalid={!!errorMessages.cardCvc}
                    $focus={focusStripe.cardCvc}
                  >
                    <Label $isInvalid={!!errorMessages.cardCvc}>
                      {t('ADD_PAYMENT_CVC')}
                    </Label>
                    <CardCvcElement
                      onChange={handleChangeStripe}
                      onFocus={handleFocusStripe}
                      onBlur={handleBlurStripe}
                      options={{
                        placeholder: t('ADD_PAYMENT_CVC'),
                        style: {
                          base: inputStyle as any,
                          invalid: errorStyle,
                        },
                      }}
                    />
                  </StripeInputStyle>
                  {errorMessages.cardCvc && (
                    <InlineMessage $inlineMessage={!!errorMessages.cardCvc}>
                      {errorMessages.cardCvc}
                    </InlineMessage>
                  )}
                </StripeElementWrapper>
              </CardExpirySection>

              {/* Cardholder name */}
              <TextField
                id="cardholder"
                name="cardholder"
                setting="mtr"
                labelText={t('ADD_PAYMENT_CARDHOLDER')}
                type="text"
                required
                error={
                  cardholderError ? t('ADD_PAYMENT_ERROR_CARDHOLDER_NAME') : ''
                }
                onChange={(e: ChangeEvent) => handleChangeCardholder(e)}
                onBlur={(e: Event) => handleChangeCardholder(e)}
                wrapperStyles={{
                  paddingRight: '0px',
                  paddingBottom: '32px',
                  marginBottom: '0px',
                }}
                inputStyle={{
                  height: '44px',
                  borderRadius: '5px',
                }}
                labelStyle={{ fontSize: '14px' }}
                errorStyle={{ marginTop: '8px' }}
              />
            </div>

            {/* Submit button */}
            <FlexButtonWrapper>
              <FormButton
                setting="mtr"
                variant="tertiary"
                fontSize="14px"
                type="button"
                onClick={() => setShowCancel(true)}
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                }}
              >
                {t('PURCHASE_CANCEL_BUTTON')}
              </FormButton>
              <FinishReviewButton
                setting="mtr"
                fontSize="14px"
                type="submit"
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                  paddingTop: isFetching ? '14px' : '',
                }}
              >
                {isFetching ? (
                  <SpinningIcon>
                    <MaterialIcon name="Rotate" />
                  </SpinningIcon>
                ) : (
                  t('PURCHASE_FINISH_REVIEW_BUTTON')
                )}
              </FinishReviewButton>
            </FlexButtonWrapper>
          </form>
        </CheckoutBox>

        {/* Modal */}
        <PurchaseCancelModal show={showCancel} setShowCancel={setShowCancel} />
      </Container>
    </PurchaseWrapper>
  );
};

export default StripeCheckoutForm;
