import { MTRFullLogo, MaterialIcon } from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logout } from 'src/features/Auth';
import RadioStationModal from 'src/features/RadioStationModal';
import theme from 'src/styles/theme';
import { Attachmoney, Sell } from '@dsny/dsny-component-library/icons';
import PromoPriceModal from 'src/features/PromoPriceModal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import PricingModal from 'src/features/PricingModal';
import {
  NavContainer,
  Menu,
  MenuItem,
  Hamburguer,
  SignoutButton,
  LogoWrapper,
  StationListButton,
  IconWrapper,
  PromoPriceButton,
  PricingButton,
} from './Navbar.styles';

const SHOWED_PROMO_MODAL = 'SHOWED_PROMO_MODAL';

// TODO will be implemented when we have more language options
// type DropListItem = {
//   description: string;
//   code: string;
// };

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showStationModal, setShowStationModal] = useState(false);
  const [showPromoPriceModal, setShowPromoPriceModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const { promotionData } = useSelector(
    (state: RootState) => state.dashboard.promotion
  );

  // Show Promo Price modal and update local storage
  useEffect(() => {
    if (promotionData) {
      const showPromoModal = JSON.parse(
        localStorage.getItem(SHOWED_PROMO_MODAL) || '[]'
      ) as string[];

      const { title } = promotionData;

      const isTitlePresent = showPromoModal.includes(title);

      if (!isTitlePresent) {
        showPromoModal.push(title);

        localStorage.setItem(
          SHOWED_PROMO_MODAL,
          JSON.stringify(showPromoModal)
        );

        setShowPromoPriceModal(true);
      }
    }
  }, [promotionData]);

  // TODO will be implemented when we have more language options
  // const language = languages.find((lang) => lang.code === i18n.language);
  // const [option, setOption] = useState(
  //   language?.description ? language.description : 'English'
  // );

  const signOut = async () => {
    const isUserLoggedOut = await logout();
    if (isUserLoggedOut) {
      window.location.reload();
    }
  };

  // TODO will be implemented when we have more language options
  // const changeLanguage = (item: DropListItem) => {
  //   setOption(item.description);
  //   i18n.changeLanguage(item.code);
  // };

  return (
    <NavContainer>
      <LogoWrapper>
        <MTRFullLogo />
      </LogoWrapper>
      <Hamburguer onClick={() => setIsOpen(!isOpen)}>
        <span />
        <span />
        <span />
      </Hamburguer>

      <Menu isOpen={isOpen}>
        <MenuItem>
          {/* TODO will be implemented when we have more language options */}
          {/* <LanguageMenu>
            <Dropdown
              width="184"
              headerAlign="center"
              onSelect={changeLanguage}
              listItems={languages}
              item={option}
              CustomIcon={LanguageIcon}
            />
          </LanguageMenu> */}

          {/* Promo Price modal button */}
          {!!promotionData && (
            <div>
              <PromoPriceButton onClick={() => setShowPromoPriceModal(true)}>
                <IconWrapper>
                  <Sell fill={theme.colors.mtr60} />
                </IconWrapper>
                {promotionData.name}
              </PromoPriceButton>
              <PromoPriceModal
                promotionData={promotionData}
                showPromoPriceModal={showPromoPriceModal}
                setShowPromoPriceModal={setShowPromoPriceModal}
              />
            </div>
          )}

          {/* Pricing modal button */}
          <div>
            <PricingButton onClick={() => setShowPricingModal(true)}>
              <IconWrapper>
                <Attachmoney />
              </IconWrapper>
              {t('PRICING_MODAL_NAV_CTA')}
            </PricingButton>
            <PricingModal
              showPricingModal={showPricingModal}
              setShowPricingModal={setShowPricingModal}
            />
          </div>

          {/* Station list modal button */}
          <div>
            <StationListButton onClick={() => setShowStationModal(true)}>
              <IconWrapper>
                <MaterialIcon name="Radio" filled={false} />
              </IconWrapper>
              {t('NAVBAR_STATION_LIST_MODAL')}
            </StationListButton>
            <RadioStationModal
              showStationModal={showStationModal}
              setShowStationModal={setShowStationModal}
            />
          </div>

          {/* Signout button */}
          <SignoutButton
            setting="mtr"
            variant="secondary"
            fontSize="14px"
            type="submit"
            data-cy="sign_out"
            onClick={signOut}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('NAVBAR_SIGNOUT_CTA_BUTTON')}
          </SignoutButton>
        </MenuItem>
      </Menu>
    </NavContainer>
  );
};

export default Navbar;
