import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';

import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { TrackingStatusEnum } from 'src/features/Dashboard';
import { format } from 'date-fns-tz';
import { convertDateToUTC } from 'src/utils/Formatter';
import { getPackageList } from './SelectPackage.thunks';
import { PackageObj, selectPlan } from './SelectPackage.slice';
import {
  PurchaseWrapper,
  FormBox,
  Container,
  StepsWrapper,
  ClickableStep,
  FlexButtonWrapper,
  FormButton,
} from '../Purchase.styles';
import { Title, SubTitle, ChargedNote } from './SelectPackage.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import { getCurrentPaymentMethod } from '../AddPayment';
import { AddTrackList } from '../MultiAsset/MultiAsset.slice';
import RegularPackages from './PromoPackages/RegularPackages';
import PromoPackages from './PromoPackages/PromoPackages';

const SelectPackage: React.FC = () => {
  const { t } = useTranslation();
  const [showCancel, setShowCancel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { packages } = useSelector((state: RootState) => state.selectPackage);
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  useEffect(() => {
    if (tracks.length) {
      dispatch(getPackageList(tracks.length));
      dispatch(getCurrentPaymentMethod());
    } else if (selectedSong) {
      dispatch(getPackageList(1));
      dispatch(getCurrentPaymentMethod());
    }

    if (!tracks.length && !selectedSong) {
      navigate('/payment/multiasset');
    }
  }, [tracks]);

  // Getting discount percentage
  const discountPercentage = (discount: number) => {
    return discount * 100;
  };

  const getQuantity = () => {
    return selectedSong && tracks.length === 0 ? 1 : tracks.length;
  };

  // Handling selected plan
  const selectPlanSubmit = (selectedPackage: PackageObj | undefined) => {
    dispatch(selectPlan(selectedPackage));
    navigate('/payment/addpayment');
  };

  // Rendering breadcrumb
  const breadCrumb = () => {
    if (tracks.length > 0) {
      return (
        <StepsWrapper>
          <ClickableStep
            $isClickable
            onClick={() => navigate('/payment/multiasset')}
          >
            <Icon
              name="ArrowLeft"
              style={{
                width: '14px',
                height: '14px',
                marginRight: '4px',
                color: theme.colors.neutralW30,
              }}
            />
            {t('SIGNUP_STEP_TWO')}
          </ClickableStep>
          &nbsp;{t('SIGNUP_OF_3_STEP')}
        </StepsWrapper>
      );
    }
    return (
      <StepsWrapper>
        <ClickableStep>{t('SIGNUP_STEP_ONE')}</ClickableStep>&nbsp;
        {t('SIGNUP_OF_2_STEP')}
      </StepsWrapper>
    );
  };

  // Getting select package title and description based on 4 scenarios
  const getSelectPackageText = () => {
    /* Scenario 0 - first purchase or subsequent ones */
    if (tracks.length > 0) {
      return (
        <>
          <Title>{t('SELECT_PACKAGE_MULTI_ASSET_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_MULTI_ASSET_SUBTITLE"
              values={{
                number: tracks.length,
              }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    if (
      selectedSong &&
      selectedSong.selected_tracking_period.remainingDays >= 0
    ) {
      const currentDate = new Date(); // Get the current date
      const { remainingDays } = selectedSong.selected_tracking_period; // Get the remaining days from selectedSong

      const extensionStartDate = new Date();
      const days = remainingDays < 0 ? 0 : remainingDays;
      extensionStartDate.setDate(currentDate.getDate() + days);

      const formattedExtensionStartDate = format(
        convertDateToUTC(new Date(extensionStartDate)),
        'MMM dd, yyyy'
      );

      return (
        <>
          <Title>{t('SELECT_PACKAGE_EXTEND_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_SUBTITLE_ACTIVE_WARNING"
              values={{ extensionStartDate: formattedExtensionStartDate }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    if (
      selectedSong &&
      selectedSong.selected_tracking_period.status ===
        TrackingStatusEnum.EXPIRED
    ) {
      const { remainingDays } = selectedSong.selected_tracking_period;
      return (
        <>
          <Title>{t('SELECT_PACKAGE_EXTEND_TITLE')}</Title>
          <SubTitle>
            <Trans
              i18nKey="SELECT_PACKAGE_SUBTITLE_WITHIN_90"
              values={{
                number: Math.abs(remainingDays),
                day: Math.abs(remainingDays) > 0 ? 'days' : 'day',
              }}
              components={{ bold: <strong /> }}
            />
          </SubTitle>
        </>
      );
    }

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
    return (
      <>
        <Title>{t('SELECT_PACKAGE_RENEW_TITLE')}</Title>
        <SubTitle>
          <Trans
            i18nKey="SELECT_PACKAGE_SUBTITLE"
            components={{ bold: <strong /> }}
          />
        </SubTitle>
      </>
    );
  };

  return (
    <PurchaseWrapper>
      <Container>
        <FormBox>
          {breadCrumb()}
          {getSelectPackageText()}

          {/* Packages */}
          {packages[0]?.promotion ? (
            <PromoPackages
              packages={packages}
              discountPercentage={discountPercentage}
              getQuantity={getQuantity}
              selectPlanSubmit={selectPlanSubmit}
            />
          ) : (
            <RegularPackages
              packages={packages}
              discountPercentage={discountPercentage}
              getQuantity={getQuantity}
              selectPlanSubmit={selectPlanSubmit}
            />
          )}

          {/* Charge Note */}
          <ChargedNote>
            <Trans
              i18nKey="MULTI_ASSET_CHARGE_NOTE"
              components={{ bold: <strong /> }}
            />
          </ChargedNote>

          {/* Cancel Button */}
          <FlexButtonWrapper>
            <FormButton
              setting="mtr"
              variant="tertiary"
              fontSize="14px"
              type="button"
              onClick={() => setShowCancel(true)}
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
              }}
            >
              {t('PURCHASE_CANCEL_BUTTON')}
            </FormButton>
          </FlexButtonWrapper>
        </FormBox>

        {/* Modal */}
        <PurchaseCancelModal show={showCancel} setShowCancel={setShowCancel} />
      </Container>
    </PurchaseWrapper>
  );
};

export default SelectPackage;
