/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { noop } from 'lodash';
import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import { FlexButtonWrapper, ModalGoToDashboardButton } from './Purchase.styles';
import { resetStateAddPayment } from './AddPayment';
import { AddTrackList, resetStateAddTrack } from './MultiAsset';
import { resetStateSelectPackage } from './SelectPackage';
import {
  clearSongSelection,
  resetStateDashBoard,
  TrackingStatusEnum,
} from '../Dashboard';

export const PurchaseSuccessModal = ({ show, setShowSuccess }: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const handleSuccessModal = () => {
    dispatch(resetStateAddPayment());
    dispatch(resetStateAddTrack());
    dispatch(resetStateSelectPackage());
    dispatch(clearSongSelection());
    dispatch(resetStateDashBoard());
    setShowSuccess(false);
    navigate('/dashboard');
  };

  // Getting modal text based on 3 scenarios (Renewal - Extension - Default)
  const modalText = () => {
    /* Renew state - expired past 90 day period */
    if (
      !tracks ||
      (tracks.length === 0 &&
        selectedSong?.selected_tracking_period.status ===
          TrackingStatusEnum.PAST_EXPIRED)
    ) {
      return (
        <Trans
          i18nKey="PURCHASE_SUCESS_MODAL_RENEW_DESC"
          values={{ song: selectedSong?.title }}
          components={{ bold: <strong /> }}
        />
      );
    }

    /* Extend state - within 90 day period */
    if (!tracks || tracks.length === 0) {
      return (
        <Trans
          i18nKey="PURCHASE_SUCESS_MODAL_EXTEND_DESC"
          values={{ song: selectedSong?.title }}
          components={{ bold: <strong /> }}
        />
      );
    }
    /* Default state */
    return <div>{t('PURCHASE_SUCESS_MODAL_DESC')}</div>;
  };

  return (
    <Modal
      isOpen={show}
      onClose={noop}
      containerStyles={{
        width: '504px',
        padding: '40px',
      }}
    >
      <div
        className="heading"
        style={{
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '33px',
          color: theme.colors.neutralW20,
          marginBottom: '8px',
        }}
      >
        <div>{t('PURCHASE_SUCCESS_MODAL_TITLE')}</div>
      </div>

      <div
        style={{
          fontSize: '14px',
          lineHeight: '19px',
          color: theme.colors.neutralW20,
          marginBottom: '40px',
        }}
      >
        {modalText()}
      </div>

      <div
        className="footer"
        style={{
          color: 'black',
        }}
      >
        <FlexButtonWrapper>
          <ModalGoToDashboardButton
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleSuccessModal}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('PURCHASE_SUCESS_MODAL_GO_TO_DASHBOARD_BUTTON')}
          </ModalGoToDashboardButton>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
