import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';

interface PurchaseItems {
  artist: string;
  title: string;
  upload_s3_bucket: string;
  upload_s3_key: string;
  src_filename: string;
  track_id: number;
  start_date: string;
}

export interface PurchaseCheckoutList {
  identifier: string;
  plan_id: string;
  items: PurchaseItems[];
  payment_method: {
    updated: false;
    old_payment_method_id: string;
  };
}

export interface PurchaseCheckout {
  identifier: string;
  artist: string;
  title: string;
  upload_s3_bucket: string;
  upload_s3_key: string;
  src_filename?: string;
  plan_id: string;
  payment_method: {
    is_new: false;
    old_payment_method_id: string;
  };
}

export interface PurchaseExtensionRenewal {
  identifier: string;
  company_id: string;
  song_id: string;
  plan_id: string;
}

export const getCurrentPaymentMethod = createAsyncThunk(
  'CURRENT_PAYMENT_METHOD',
  async () => {
    return axios
      .get('/subscriptions/mtr/payment-method/current')
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const postPurchasePrepare = createAsyncThunk(
  'POST_PURCHASE_PREPARE',
  async () => {
    return axios
      .post('/subscriptions/mtr/purchase/prepare')
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseCheckout = createAsyncThunk(
  'GET_PURCHASE_CHECKOUT',
  async (purchaseCheckout: PurchaseCheckoutList) => {
    return axios
      .post('/subscriptions/mtr/purchase/checkout/all', purchaseCheckout)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseRenewal = createAsyncThunk(
  'GET_PURCHASE_RENEWAL',
  async (purchaseRenewal: PurchaseExtensionRenewal) => {
    return axios
      .post('/subscriptions/mtr/purchase/renewal', purchaseRenewal)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseExtension = createAsyncThunk(
  'GET_PURCHASE_EXTENSION',
  async (purchaseExtension: PurchaseExtensionRenewal) => {
    return axios
      .post('/subscriptions/mtr/purchase/extension', purchaseExtension)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);
