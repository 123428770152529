import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';

declare type Track = {
  artist: string;
  title: string;
  upload_s3_bucket: string;
  upload_s3_key: string;
  src_filename: string;
};

declare type S3RequestData = {
  upload_uuid: string;
  tracks: Track[];
};

export const uploadFilesToPlaylist = createAsyncThunk(
  'UPLOAD_FILE_TO_PLAYLIST',
  async (s3RequestData: S3RequestData) => {
    return axios
      .post('/assets/mtr/playlist', s3RequestData)
      .then((response) => {
        return response.data;
      });
  }
);

export const deleteTrack = createAsyncThunk(
  'DELETE_TRACK',
  async (ids: string[]) => {
    return axios
      .delete('/assets/mtr/songs', {
        data: { song_ids: ids },
      })
      .then((response) => {
        return response.data;
      });
  }
);

export default uploadFilesToPlaylist;
