import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import {
  getCurrentPaymentMethod,
  postPurchasePrepare,
  getPurchaseCheckout,
  getPurchaseRenewal,
  getPurchaseExtension,
} from './AddPayment.thunks';

export interface CurrentPaymentMethodObj {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

export interface AddPaymentObj {
  currentPaymentMethod: CurrentPaymentMethodObj;
  paymentPrepare: PaymentPrepare;
  purchaseCheckout: PurchaseCheckoutStatus;
  isFetching: boolean;
  hasError: boolean;
}

export interface PaymentPrepare {
  clientSecret: string;
}

export interface PurchaseCheckoutStatus {
  isPurchaseSuccessful: boolean;
}

const AddPaymentSlice = createSlice({
  name: 'AddPayment',
  initialState: {
    currentPaymentMethod: {},
    paymentPrepare: {},
    purchaseCheckout: { isPurchaseSuccessful: false },
    isFetching: false,
    hasError: false,
  } as AddPaymentObj,
  extraReducers: (builder) =>
    builder
      .addCase(getCurrentPaymentMethod.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCurrentPaymentMethod.fulfilled, (state, { payload }) => {
        state.currentPaymentMethod = payload.data.current_payment_method;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getCurrentPaymentMethod.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get current payment method')
        );
      })
      .addCase(postPurchasePrepare.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(postPurchasePrepare.fulfilled, (state, { payload }) => {
        state.paymentPrepare.clientSecret = payload.data?.client_secret;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(postPurchasePrepare.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to purchase prepare')
        );
      })
      .addCase(getPurchaseCheckout.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseCheckout.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseCheckout.rejected, (state) => {
        state.isFetching = false;
        state.hasError = true;
        state.purchaseCheckout.isPurchaseSuccessful = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase checkout')
        );
      })
      .addCase(getPurchaseRenewal.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseRenewal.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseRenewal.rejected, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = false;
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase renewal')
        );
      })
      .addCase(getPurchaseExtension.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseExtension.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseExtension.rejected, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = false;
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase extension')
        );
      }),
  reducers: {
    resetStateAddPayment: () => {
      return {
        currentPaymentMethod: {},
        paymentPrepare: {},
        purchaseCheckout: { isPurchaseSuccessful: false },
        isFetching: false,
        hasError: false,
      } as AddPaymentObj;
    },
  },
});

export const { resetStateAddPayment } = AddPaymentSlice.actions;

export default AddPaymentSlice.reducer;
