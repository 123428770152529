/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxPopover,
  Pill,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { selectSong, Song } from '../Dashboard.slice';
import { getSongs } from '../Dashboard.thunks';
import {
  ComboboxInputStyles,
  ComboboxListStyles,
  ComboboxOptionStyles,
  ComboboxOptionWrapper,
  ComboboxStyles,
  ComboboxWrapper,
  DropdownInputWrapper,
  EmptyStateMessageStyle,
  ExpiredSongListWrapper,
  ExpiredStyleButton,
  ActivePillStyles,
  ExpiredPillStyles,
  SongWrapper,
  TabWrapper,
  TrackingSongListWrapper,
  TrackingStyleButton,
  ScheduledPillStyles,
  TrackingTitleWrapper,
  ExpiredTitleWrapper,
  SongInfoWrapper,
} from './SongSelection.styles';

export interface ComboValueProps {
  id: number;
  description?: string;
  subdescription?: string;
}

// Sample song
const SAMPE_SONG_ID = 'sample-song-200000';

const SongSelection: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isSampleSong, setIsSampleSong] = useState(false);
  const [isExpiredListSelected, setIsExpiredListSelected] = useState(false);
  const { songs, expiredSongs } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );
  const [displaySongs, setDisplaySongs] = useState(songs);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const songId = searchParams.get('songId');
  const navigate = useNavigate();

  const [value, setValue] = useState({
    id: 0,
  } as any);

  useEffect(() => {
    dispatch(getSongs());
  }, []);

  useEffect(() => {
    const displaySongList = isExpiredListSelected ? expiredSongs : songs;
    setDisplaySongs(displaySongList);
  }, [songs, expiredSongs, isExpiredListSelected]);

  useEffect(() => {
    const isSample =
      displaySongs?.length === 1 && displaySongs[0].song_id === SAMPE_SONG_ID;
    setIsSampleSong(isSample);
  }, [displaySongs]);

  // Handling findSelectedItemIndex
  const findSelectedItemIndex = (song: Song) => {
    const index =
      displaySongs?.findIndex((s) => s.song_id === song.song_id) || 0;
    return index + 1;
  };

  // Handling finding song
  const findSong = (songList: Song[], expiredSongList: Song[]) => {
    const songIndex = songList.findIndex((s: any) => s.song_id === songId);
    const song = songIndex !== -1 ? songList[songIndex] : null;

    const expiredSongIndex = expiredSongList.findIndex(
      (s: any) => s.song_id === songId
    );
    const expiredSong =
      expiredSongIndex !== -1 ? expiredSongList[expiredSongIndex] : null;

    setIsExpiredListSelected(!!expiredSong);
    return {
      song: song || expiredSong || songList[0] || expiredSongList[0],
      index: songIndex + 1 || expiredSongIndex + 1 || 1,
      isExpired: !!expiredSong,
    };
  };

  const updateSongSelected = (
    song: Song,
    index: number,
    isExpired?: boolean
  ) => {
    dispatch(selectSong(song));
    setValue({
      id: song.song_id,
      description: song.title,
      subdescription: song.artist,
      index,
      isExpired: isExpired !== undefined ? isExpired : isExpiredListSelected,
      isScheduled: song.is_scheduled,
    });
    navigate(`/dashboard?songId=${song.song_id}`);
  };

  useEffect(() => {
    if (songs?.length || expiredSongs?.length) {
      const { song, index, isExpired } = findSong(
        songs || [],
        expiredSongs || []
      );

      updateSongSelected(song, index, isExpired);
    }
  }, [songs, expiredSongs, songId]);

  // Handling Combobox onChange
  const onChange = (song: Song) => {
    const index = findSelectedItemIndex(song);
    updateSongSelected(song, index);
  };

  // Handling song list tab selection
  const handleSongListTabSelection = (displayExpiredSong: boolean) => {
    setIsExpiredListSelected(displayExpiredSong);
    if (displayExpiredSong) {
      setDisplaySongs(expiredSongs);
    } else {
      setDisplaySongs(songs);
    }
  };

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Getting combobox length dynamically
  const getComboboxSize = (listLength?: number) => {
    const baseSize = 40;
    const itemSize = 56;
    /* If the list is empty a warning message will be displayed, 1.3 guarantees enough space for this. For sample songs, tabs will not be displayed, reducing the size of a line item to 0.3  */
    const itemQty = isSampleSong ? 0.3 : listLength || 1.3;
    const totalSize = baseSize + itemSize * itemQty;
    return `${totalSize.toString()}px`;
  };

  // Getting selection index
  const getSelectionIndex = (valueSelected: any) => {
    return valueSelected.isExpired === isExpiredListSelected
      ? valueSelected.index
      : 0;
  };

  // Getting pill type
  const getPillType = (isExpired: boolean, isSchedule: boolean) => {
    if (isSchedule) {
      return { label: 'DASHBOARD_TAB_SCHEDULED', style: ScheduledPillStyles };
    }

    if (isExpired) {
      return { label: 'DASHBOARD_TAB_EXPIRED', style: ExpiredPillStyles };
    }

    return { label: 'DASHBOARD_TAB_TRACKING', style: ActivePillStyles };
  };

  // Getting song input description
  const getSongDescription = (
    desc: string,
    isExpired: boolean,
    isScheduled: boolean
  ) => {
    const { label, style } = getPillType(isExpired, isScheduled);
    return (
      <DropdownInputWrapper>
        <SongWrapper>{desc}</SongWrapper>
        {!isSampleSong && (
          <Pill label={t(label)} type="leading" style={{ ...style }} />
        )}
      </DropdownInputWrapper>
    );
  };

  return (
    <>
      {/* Song Selection */}
      <ComboboxWrapper>
        <Combobox
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          style={{ ...ComboboxStyles }}
          value={
            {
              id: value.id,
              description: getSongDescription(
                value.description,
                value.isExpired,
                value.isScheduled
              ),
              subdescription: value.subdescription,
            } as any
          }
        >
          <ComboboxInput
            placeholder="Placeholder"
            highlightcolor={theme.colors.mtr60}
            style={{ ...ComboboxInputStyles }}
          />

          <ComboboxPopover
            style={{
              maxHeight: '323px',
              height: getComboboxSize(displaySongs?.length),
              borderRadius: '5px',
              marginTop: '8px',
              borderColor: theme.colors.white,
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Tracking and Expired tabs */}
            {!isSampleSong && (
              <TabWrapper>
                <TrackingStyleButton
                  isExpiredListSelected={isExpiredListSelected}
                  onClick={() => handleSongListTabSelection(false)}
                >
                  {t('DASHBOARD_TAB_TRACKING')}
                </TrackingStyleButton>
                <ExpiredStyleButton
                  isExpiredListSelected={isExpiredListSelected}
                  onClick={() => handleSongListTabSelection(true)}
                >
                  {t('DASHBOARD_TAB_EXPIRED')}
                </ExpiredStyleButton>
              </TabWrapper>
            )}

            <ComboboxList style={{ ...ComboboxListStyles }}>
              {/* Tracking empty state message */}
              {!displaySongs?.length && !isExpiredListSelected && (
                <EmptyStateMessageStyle>
                  {t('DASHBOARD_TRACKING_EMPTY_LIST_MESSAGE_TITLE')}
                  <p>{t('DASHBOARD_TRACKING_EMPTY_LIST_MESSAGE_DESC')}</p>
                </EmptyStateMessageStyle>
              )}

              {/* Expired empty state message */}
              {!displaySongs?.length && isExpiredListSelected && (
                <EmptyStateMessageStyle>
                  {t('DASHBOARD_EXPIRED_EMPTY_LIST_MESSAGE_TITLE')}
                  <p>{t('DASHBOARD_EXPIRED_EMPTY_LIST_MESSAGE_DESC')}</p>
                </EmptyStateMessageStyle>
              )}

              {displaySongs?.map((song: any, i) => {
                return (
                  <ComboboxOptionWrapper
                    key={song.song_id}
                    selectedItemIndex={getSelectionIndex(value)}
                    style={{ ...ComboboxOptionStyles }}
                    value={song}
                  >
                    {isExpiredListSelected ? (
                      // Expired list
                      <ExpiredSongListWrapper
                        itemIndex={i + 1}
                        selectedItemIndex={getSelectionIndex(value)}
                      >
                        <SongInfoWrapper>
                          <ExpiredTitleWrapper>
                            {song.title}
                          </ExpiredTitleWrapper>
                          <p>{song.artist}</p>
                        </SongInfoWrapper>
                      </ExpiredSongListWrapper>
                    ) : (
                      // Tracking list
                      <TrackingSongListWrapper>
                        <SongInfoWrapper>
                          <TrackingTitleWrapper>
                            {song.title}
                          </TrackingTitleWrapper>
                          <p>{song.artist}</p>
                        </SongInfoWrapper>
                        {/* Scheduled tag */}
                        {song.is_scheduled && (
                          <Pill
                            label={t('DASHBOARD_TAB_SCHEDULED')}
                            type="leading"
                            style={{ ...ScheduledPillStyles }}
                          />
                        )}
                      </TrackingSongListWrapper>
                    )}
                  </ComboboxOptionWrapper>
                );
              })}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </ComboboxWrapper>
    </>
  );
};

export default SongSelection;
