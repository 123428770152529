/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';

import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import {
  FlexButtonWrapper,
  ModalCancelButton,
  ModalContinueButton,
} from './Purchase.styles';
import { resetStateAddPayment } from './AddPayment';
import { resetStateAddTrack } from './MultiAsset';
import { resetStateSelectPackage } from './SelectPackage';

export const PurchaseCancelModal = ({ show, setShowCancel }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleCancelModal = () => {
    dispatch(resetStateAddPayment());
    dispatch(resetStateAddTrack());
    dispatch(resetStateSelectPackage());
    setShowCancel(false);
    navigate('/dashboard');
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShowCancel(false)}
      containerStyles={{
        width: '504px',
        height: '248px',
        padding: '40px',
      }}
    >
      <div
        className="heading"
        style={{
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '33px',
          color: theme.colors.neutralW20,
          marginBottom: '8px',
        }}
      >
        <div>{t('PURCHASE_CANCEL_MODAL_TITLE')}</div>
      </div>

      <p
        style={{
          fontSize: '14px',
          lineHeight: '19px',
          color: theme.colors.neutralW20,
          marginBottom: '40px',
        }}
      >
        {t('PURCHASE_CANCEL_MODAL_DESC')}
      </p>

      <div
        className="footer"
        style={{
          color: 'black',
        }}
      >
        <FlexButtonWrapper>
          <ModalCancelButton
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={handleCancelModal}
            style={{
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('PURCHASE_CANCEL_MODAL_BUTTON')}
          </ModalCancelButton>
          <ModalContinueButton
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={() => setShowCancel(false)}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('PURCHASE_CANCEL_MODAL_CONTINUE_BUTTON')}
          </ModalContinueButton>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
