import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import {
  getPackageList,
  getSingleSongPackageList,
} from './SelectPackage.thunks';

interface Plan {
  id: string;
  name: string;
  description: string;
  price: string;
  duration: number;
  discount: number;
}

interface Promotion {
  title: string;
  name: string;
  description: string;
  disclaimer: string;
  discount_percentage: number;
  start_date: string;
  expiration_date: string;
}

interface Summary {
  unit_price: string;
  subtotal: string;
  promo_discount_amount: string;
  unit_price_after_promo: string;
  subtotal_after_promo: string;
  taxes_and_fees: string;
  total: string;
  quantity: number;
}

export interface PackageObj {
  plan: Plan;
  promotion: Promotion;
  summary: Summary;
}

export interface SelectPackageObj {
  packages: [PackageObj?];
  packagesSingleSong: [PackageObj?];
  selectedPackage?: PackageObj;
  isFetching: boolean;
  hasError: boolean;
}

const SelectPackageSlice = createSlice({
  name: 'SelectPackage',
  initialState: {
    packages: [],
    packagesSingleSong: [],
    selectedPackage: undefined,
    isFetching: false,
    hasError: false,
  } as SelectPackageObj,
  extraReducers: (builder) =>
    builder
      .addCase(getPackageList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPackageList.fulfilled, (state, { payload }) => {
        state.packages = payload.data;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPackageList.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get package list')
        );
        return state;
      })
      .addCase(getSingleSongPackageList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSingleSongPackageList.fulfilled, (state, { payload }) => {
        state.packagesSingleSong = payload.data;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getSingleSongPackageList.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get package single song list')
        );
        return state;
      }),
  reducers: {
    resetStateSelectPackage: () => {
      return {
        packages: [],
        packagesSingleSong: [],
        isFetching: false,
        selectedPackage: undefined,
        hasError: false,
      } as SelectPackageObj;
    },
    selectPlan: (state, action) => {
      state.selectedPackage = action.payload;
    },
  },
});

export const { resetStateSelectPackage, selectPlan } =
  SelectPackageSlice.actions;

export default SelectPackageSlice.reducer;
