/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { heatmapDataTemplate } from 'src/utils/HeatmapTemplate';
import * as Sentry from '@sentry/react';
import { convertDateToUTC, formatTextForSentryLog } from 'src/utils/Formatter';
import {
  getSongOverview,
  getSongs,
  getSongDaily,
  getSongHeatmap,
  getStations,
  getCities,
  getSongOverviewCompare,
  getStationsCompare,
  getCitiesCompare,
  getSongDailyCompare,
  getSongHeatmapCompare,
  getPromotions,
} from './Dashboard.thunks';

const lastSevenDaysTimeFilter = 7;

export const stationsSortKeys = new Map([
  ['Unfold', 'UnfoldAscending'],
  ['UnfoldAscending', 'UnfoldDescending'],
  ['UnfoldDescending', 'Unfold'],
]);

export interface Song {
  title: string;
  artist: string;
  song_id: string;
  tracking_periods: TrackingPeriod[];
  selected_tracking_period: SelectedTrackingPeriod;
  is_scheduled: boolean;
}

export interface SelectedTrackingPeriod {
  index: number;
  status: TrackingStatusEnum;
  remainingDays: number;
  tracking_period: TrackingPeriod;
  allTimeStartDate: string;
}

export interface TrackingPeriod {
  start_tracking_on: string;
  end_tracking_on: string;
  monitoring_end_on: string;
  tracking_period_id: number;
}

export interface OverviewMetric {
  count: number;
  difference: number;
  previous_count: number;
}

export interface OverviewCompare {
  all_time_plays: OverviewMetric;
  unique_cities: OverviewMetric;
  unique_stations: OverviewMetric;
}

export interface OverviewType {
  all_time_plays: number;
  unique_cities: number;
  unique_stations: number;
  all_time_plays_change: number;
  unique_cities_change: number;
  unique_stations_change: number;
  compare: OverviewCompare;
}

export interface SongSelection {
  songs?: Song[];
  expiredSongs?: Song[];
  selectedSong?: Song;
  isFetchingSongSelection: boolean;
  isLoadingActive?: boolean;
  hasError: boolean;
}

export interface SongOverview {
  overview?: OverviewType;
  overviewCompare?: OverviewCompare;
  isFetchingSongOverview: boolean;
  hasError: boolean;
  hasBannerWarning: boolean;
}

export interface SongChartData {
  id: string;
  data: ChartData[];
}

export interface ToolTipData {
  title: string;
  current: string;
  previous: string;
  change: string;
}

export interface ChartData {
  x: string | number;
  y: string | number;
  tool_tip?: string;
  toolTipData?: ToolTipData;
}

export interface songDailyData {
  title: string;
  song_id: string;
  data: SongChartData;
}

export interface songDailyDataList {
  title: string;
  song_id: string;
  data: SongChartData[];
}

export interface SongDaily {
  songDailyData?: songDailyData;
  songDailyDataList?: songDailyDataList;
  isFetchingSongDaily: boolean;
  hasError: boolean;
  hasBannerWarning: boolean;
}

export interface songHeatmapData {
  title: string;
  song_id: string;
  Days: SongChartData[];
  colorRange?: number;
}

export interface SongHeatmap {
  songHeatmapData?: songHeatmapData;
  songHeatmapDataCompare?: songHeatmapData;
  isFetchingSongHeatmap: boolean;
  hasError: boolean;
  hasBannerWarning: boolean;
}

export interface location {
  city: string;
  state: string;
  state_code: string;
  country: string;
  country_code: string;
  timezone: string;
  latitude: number;
  longitude: number;
}

export interface Spins {
  station_name: string;
  station_id: string;
  count: number;
  previous_count: number;
  difference: number;
  location: location;
}

export interface SongStations {
  songStationsData?: Spins[];
  songStationsDataCompare?: Spins[];
  isFetchingSongStations: boolean;
  hasError: boolean;
  hasBannerWarning: boolean;
}

export interface SongCities {
  songCitiesData?: Spins[];
  songCitiesDataCompare?: Spins[];
  isFetchingSongCities: boolean;
  hasError: boolean;
  hasBannerWarning: boolean;
}

export interface FilterSelection {
  code: number;
}

export interface PromotionData {
  title: string;
  name: string;
  description: string;
  disclaimer: string;
  discount_percentage: number;
  start_date: string;
  expiration_date: string;
}

export interface Promotion {
  promotionData?: PromotionData;
  isFetchingPromotion: boolean;
  hasError: boolean;
}

export interface DashBoardObj {
  songSelection: SongSelection;
  songOverview: SongOverview;
  songDaily: SongDaily;
  songHeatmap: SongHeatmap;
  songStations: SongStations;
  songCities: SongCities;
  filterSelection: FilterSelection;
  promotion: Promotion;
}

export const enum TrackingStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRING = 'EXPIRING',
  EXPIRED = 'EXPIRED',
  PAST_EXPIRED = 'PAST_EXPIRED',
}

const trackingStatus = (days: number) => {
  if (days < 1 && days >= -90) {
    return TrackingStatusEnum.EXPIRED;
  }
  if (days < -90) {
    return TrackingStatusEnum.PAST_EXPIRED;
  }
  if (days <= 5) {
    return TrackingStatusEnum.EXPIRING;
  }

  return TrackingStatusEnum.ACTIVE;
};

const daysLeft = (endTrackingOn: Date) => {
  return Math.round(
    (endTrackingOn.valueOf() - new Date().valueOf()) / (1000 * 60 * 60 * 24) + 1 // adding plus one to considere last day as well
  );
};

const buildToolTip = (period: SongChartData) => {
  return period.data?.map((data) => {
    const toolTip = data.tool_tip?.split('\n');
    if (toolTip !== undefined) {
      const change = Number(toolTip[3].split(':')[1].trim() || 0);
      return {
        x: data.x,
        y: data.y,
        tool_tip: data.tool_tip,
        toolTipData: {
          title: toolTip[0],
          current: toolTip[1].split(':')[1].trim(),
          previous: toolTip[2].split(':')[1].trim(),
          change: change > 0 ? `+${change}` : String(change),
        },
      };
    }
    return data;
  });
};

const buildToolTipArray = (period: SongChartData[]) => {
  return period.map((data) => {
    return {
      id: data.id,
      data: buildToolTip(data),
    };
  });
};

const dashBoardInitialState = {
  songSelection: {
    songs: undefined,
    selectedSong: undefined,
    isFetchingSongSelection: true,
    hasError: false,
  },
  songOverview: {
    overview: undefined,
    overviewCompare: undefined,
    isFetchingSongOverview: true,
    hasError: false,
    hasBannerWarning: false,
  },
  songDaily: {
    songDailyList: undefined,
    isFetchingSongDaily: true,
    hasError: false,
    hasBannerWarning: false,
  },
  songHeatmap: {
    songHeatmapList: undefined,
    songHeatmapListCompare: undefined,
    isFetchingSongHeatmap: true,
    hasError: false,
    hasBannerWarning: false,
  },
  songStations: {
    songStationsData: undefined,
    songStationsDataCompare: undefined,
    isFetchingSongStations: true,
    hasError: false,
    hasBannerWarning: false,
  },
  songCities: {
    songCitiesData: undefined,
    isFetchingSongCities: true,
    hasError: false,
    hasBannerWarning: false,
  },
  filterSelection: {
    code: lastSevenDaysTimeFilter,
  },
  promotion: {
    promotionData: undefined,
    isFetchingPromotion: false,
    hasError: false,
  },
} as DashBoardObj;

const DashBoardSlice = createSlice({
  name: 'Dashboard',
  initialState: dashBoardInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(getSongs.pending, (state) => {
        state.songSelection.isFetchingSongSelection = true;
      })
      .addCase(getSongs.fulfilled, (state, { payload }) => {
        state.songSelection.songs = payload.data.active_songs;
        state.songSelection.expiredSongs = payload.data.expired_songs;
        state.songSelection.hasError = false;
        state.songSelection.isFetchingSongSelection = false;
      })
      .addCase(getSongs.rejected, (state) => {
        state.songSelection.hasError = true;
        state.songSelection.isFetchingSongSelection = false;
        Sentry.captureException(formatTextForSentryLog('failed to get songs'));
      })
      .addCase(getSongOverview.pending, (state) => {
        state.songOverview.isFetchingSongOverview = true;
      })
      .addCase(getSongOverview.fulfilled, (state, { payload }) => {
        state.songOverview.overview = payload.data;
        state.songOverview.hasError = false;
        state.songOverview.isFetchingSongOverview = false;
      })
      .addCase(getSongOverview.rejected, (state) => {
        state.songOverview.hasError = true;
        state.songOverview.isFetchingSongOverview = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song overview')
        );
      })
      .addCase(getSongOverviewCompare.pending, (state) => {
        state.songOverview.isFetchingSongOverview = true;
      })
      .addCase(getSongOverviewCompare.fulfilled, (state, { payload }) => {
        state.songOverview.isFetchingSongOverview = false;
        if (payload.message) {
          state.songOverview.hasBannerWarning = true;
        } else {
          state.songOverview.overviewCompare = payload.data;
          state.songOverview.hasBannerWarning = false;
        }
        state.songOverview.hasError = false;
      })
      .addCase(getSongOverviewCompare.rejected, (state) => {
        state.songOverview.hasError = true;
        state.songOverview.isFetchingSongOverview = false;
        state.songOverview.hasBannerWarning = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song overview compare')
        );
      })
      .addCase(getSongDaily.pending, (state) => {
        state.songDaily.isFetchingSongDaily = true;
      })
      .addCase(getSongDaily.fulfilled, (state, { payload }) => {
        state.songDaily.isFetchingSongDaily = false;
        state.songDaily.songDailyData = payload.data;
        state.songDaily.hasError = false;
      })
      .addCase(getSongDaily.rejected, (state) => {
        state.songDaily.isFetchingSongDaily = false;
        state.songDaily.hasError = true;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song daily')
        );
      })
      .addCase(getSongDailyCompare.pending, (state) => {
        state.songDaily.isFetchingSongDaily = true;
      })
      .addCase(getSongDailyCompare.fulfilled, (state, { payload }) => {
        state.songDaily.isFetchingSongDaily = false;
        const dataObj = payload.data;
        if (dataObj?.message) {
          state.songDaily.hasBannerWarning = true;
        } else {
          const dataList = dataObj.data;

          const currentPeriod = dataList[0] as SongChartData;
          const previousPeriod = dataList[1] as SongChartData;

          previousPeriod.data?.forEach((item, index) => {
            item.x = currentPeriod.data[index].x;
          });

          currentPeriod.data = buildToolTip(currentPeriod);
          previousPeriod.data = buildToolTip(previousPeriod);

          const songDailyDataListTemp = {
            title: payload.data.title,
            song_id: payload.data.song_id,
            data: [currentPeriod, previousPeriod],
          };
          state.songDaily.songDailyDataList = songDailyDataListTemp;
          state.songDaily.hasBannerWarning = false;
        }
        state.songDaily.hasError = false;
      })
      .addCase(getSongDailyCompare.rejected, (state) => {
        state.songDaily.isFetchingSongDaily = false;
        state.songDaily.hasError = true;
        state.songDaily.hasBannerWarning = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song daily compare')
        );
      })
      .addCase(getStations.pending, (state) => {
        state.songStations.isFetchingSongStations = true;
      })
      .addCase(getStations.fulfilled, (state, { payload }) => {
        state.songStations.isFetchingSongStations = false;
        state.songStations.songStationsData = payload.data;
        state.songStations.hasError = false;
      })
      .addCase(getStations.rejected, (state) => {
        state.songStations.isFetchingSongStations = false;
        state.songStations.hasError = true;
        Sentry.captureException(
          formatTextForSentryLog('failed to get stations')
        );
      })
      .addCase(getStationsCompare.pending, (state) => {
        state.songStations.isFetchingSongStations = true;
      })
      .addCase(getStationsCompare.fulfilled, (state, { payload }) => {
        state.songStations.isFetchingSongStations = false;
        if (payload?.data?.message) {
          state.songStations.hasBannerWarning = true;
        } else {
          state.songStations.songStationsDataCompare = payload.data;
          state.songStations.hasBannerWarning = false;
        }
        state.songStations.hasError = false;
        state.songStations.hasBannerWarning = false;
      })
      .addCase(getStationsCompare.rejected, (state) => {
        state.songStations.isFetchingSongStations = false;
        state.songStations.hasError = true;
        state.songStations.hasBannerWarning = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get stations compare')
        );
      })
      .addCase(getCities.pending, (state) => {
        state.songCities.isFetchingSongCities = true;
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        state.songCities.isFetchingSongCities = false;
        state.songCities.songCitiesData = payload.data;
        state.songCities.hasError = false;
      })
      .addCase(getCities.rejected, (state) => {
        state.songCities.isFetchingSongCities = false;
        state.songCities.hasError = true;
        Sentry.captureException(formatTextForSentryLog('failed to get cities'));
      })
      .addCase(getCitiesCompare.pending, (state) => {
        state.songCities.isFetchingSongCities = true;
      })
      .addCase(getCitiesCompare.fulfilled, (state, { payload }) => {
        state.songCities.isFetchingSongCities = false;
        if (payload?.data?.message) {
          state.songCities.hasBannerWarning = true;
        } else {
          state.songCities.songCitiesDataCompare = payload.data;
          state.songCities.hasBannerWarning = false;
        }
        state.songCities.hasError = false;
      })
      .addCase(getCitiesCompare.rejected, (state) => {
        state.songCities.isFetchingSongCities = false;
        state.songCities.hasError = true;
        state.songCities.hasBannerWarning = false;
        state.songCities.hasBannerWarning = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get cities compare')
        );
      })
      .addCase(getSongHeatmap.pending, (state) => {
        state.songHeatmap.isFetchingSongHeatmap = true;
      })
      .addCase(getSongHeatmap.fulfilled, (state, { payload }) => {
        state.songHeatmap.isFetchingSongHeatmap = false;
        const songHeatmapData = payload.data as songHeatmapData;
        state.songHeatmap.hasError = false;
        state.songCities.hasBannerWarning = false;

        const days: any[] = [];

        songHeatmapData?.Days?.forEach((day) => {
          const hoursMap = new Map();
          day.data.forEach((d) => {
            hoursMap.set(d.x, d);
          });
          days.push({ id: day.id, data: hoursMap });
        });

        const newDays: any[] = [];

        days.forEach((day) => {
          const newDay: any[] = [];
          heatmapDataTemplate.forEach((t) => {
            newDay.push(day.data.has(t.x) ? day.data.get(t.x) : t);
          });
          newDays.push({ id: day.id, data: newDay });
        });

        if (songHeatmapData?.Days) {
          songHeatmapData.Days = newDays;
        }
        state.songHeatmap.songHeatmapData = songHeatmapData;
      })
      .addCase(getSongHeatmap.rejected, (state) => {
        state.songHeatmap.isFetchingSongHeatmap = false;
        state.songHeatmap.hasError = true;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song heat map')
        );
      })
      .addCase(getSongHeatmapCompare.pending, (state) => {
        state.songHeatmap.isFetchingSongHeatmap = true;
      })
      .addCase(getSongHeatmapCompare.fulfilled, (state, { payload }) => {
        state.songHeatmap.isFetchingSongHeatmap = false;
        if (payload.message) {
          state.songHeatmap.hasBannerWarning = true;
        } else {
          const songHeatmapData = payload.data as songHeatmapData;

          let smallestValue = 0;
          let biggestValue = 0;

          const days: any[] = [];

          songHeatmapData?.Days?.forEach((day) => {
            const hoursMap = new Map();
            day.data.forEach((d) => {
              hoursMap.set(d.x, d);
              const y = Number(d.y);
              smallestValue = y < smallestValue ? y : smallestValue;
              biggestValue = y > biggestValue ? y : biggestValue;
            });
            days.push({ id: day.id, data: hoursMap });
          });

          const range = Math.max(Math.abs(smallestValue), biggestValue);

          const newDays: any[] = [];

          days.forEach((day) => {
            const newDay: any[] = [];
            heatmapDataTemplate.forEach((t) => {
              newDay.push(day.data.has(t.x) ? day.data.get(t.x) : t);
            });
            newDays.push({ id: day.id, data: newDay });
          });

          if (songHeatmapData?.Days) {
            const newDaysToolTips = buildToolTipArray(newDays);
            songHeatmapData.Days = newDaysToolTips;
            songHeatmapData.colorRange = range;
          }
          state.songHeatmap.songHeatmapDataCompare = songHeatmapData;
          state.songHeatmap.hasBannerWarning = false;
        }
        state.songHeatmap.hasError = false;
      })
      .addCase(getSongHeatmapCompare.rejected, (state) => {
        state.songHeatmap.isFetchingSongHeatmap = false;
        state.songHeatmap.hasError = true;
        state.songHeatmap.hasBannerWarning = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get song heat map compare')
        );
      })
      .addCase(getPromotions.pending, (state) => {
        state.promotion.isFetchingPromotion = true;
      })
      .addCase(getPromotions.fulfilled, (state, { payload }) => {
        state.promotion.isFetchingPromotion = false;
        state.promotion.promotionData = payload.data?.[0] ?? undefined;
        state.promotion.hasError = false;
      })
      .addCase(getPromotions.rejected, (state) => {
        state.promotion.isFetchingPromotion = false;
        state.promotion.hasError = true;
        Sentry.captureException(
          formatTextForSentryLog('failed to get promotions')
        );
      }),
  reducers: {
    resetStateDashBoard: () => {
      return dashBoardInitialState;
    },
    clearSongSelection: (state) => {
      state.songSelection.selectedSong = undefined;
      state.songSelection.songs = undefined;
    },
    selectSong: (state, action) => {
      const currentSongId = action.payload.song_id;
      const newSongId = state.songSelection.selectedSong?.song_id;

      // only updates if list of period have different values
      const currentTrackingPeriods =
        state.songSelection.selectedSong?.tracking_periods;
      const newTrackingPeriods = action.payload.tracking_periods;

      let isSameList = true;

      if (
        currentTrackingPeriods === undefined ||
        currentTrackingPeriods.length === 0 ||
        newTrackingPeriods === undefined ||
        newTrackingPeriods.length === 0
      ) {
        isSameList = false;
      }

      if (isSameList) {
        currentTrackingPeriods?.forEach((currentSong) => {
          let foundDifferent = false;

          newTrackingPeriods?.forEach((newSong: TrackingPeriod) => {
            if (currentSong.end_tracking_on !== newSong.end_tracking_on) {
              foundDifferent = true;
            }
          });

          if (foundDifferent) {
            isSameList = false;
          }
        });
      }

      if (currentSongId !== newSongId || !isSameList) {
        state.songSelection.isLoadingActive = true;
        state.songSelection.selectedSong = action.payload;
        state.songOverview.hasBannerWarning = false;
        state.songDaily.hasBannerWarning = false;
        state.songHeatmap.hasBannerWarning = false;
        state.songStations.hasBannerWarning = false;
        state.songCities.hasBannerWarning = false;
      }
    },
    selectPeriod: (state, action) => {
      const index = action.payload;
      if (
        state.songSelection.selectedSong &&
        index < state.songSelection.selectedSong?.tracking_periods.length
      ) {
        const firstPosition = 0;
        const trackingPeriod =
          state.songSelection.selectedSong?.tracking_periods[index];

        const trackingPeriodId =
          index < 0 ? index : trackingPeriod?.tracking_period_id;

        const latestTrackingPeriod =
          state.songSelection.selectedSong?.tracking_periods[firstPosition];

        const listSize =
          state.songSelection.selectedSong?.tracking_periods.length;

        const oldestTrackingIndex = listSize >= 1 ? listSize - 1 : 0;

        const oldestTrackingPeriod =
          state.songSelection.selectedSong?.tracking_periods[
            oldestTrackingIndex
          ];

        const remainingDays = daysLeft(
          new Date(latestTrackingPeriod.end_tracking_on)
        );

        const status = trackingStatus(remainingDays);

        state.songSelection.selectedSong.selected_tracking_period = {
          index: trackingPeriodId,
          tracking_period: trackingPeriod,
          remainingDays,
          status,
          allTimeStartDate: oldestTrackingPeriod.start_tracking_on,
        } as SelectedTrackingPeriod;
      }
    },
    setLoadToInactive: (state) => {
      state.songSelection.isLoadingActive = false;
    },
    setFilterSelection: (state, action) => {
      state.filterSelection.code = action.payload;
    },
    clearHeatMapData: (state) => {
      const newHeatMap = {
        songHeatmapList: state.songHeatmap.songHeatmapData,
        songHeatmapListCompare: undefined,
        isFetchingSongHeatmap: false,
        hasError: false,
        hasBannerWarning: false,
      };
      state.songHeatmap = newHeatMap;
    },
    clearHeatMapDataComparison: (state) => {
      const newHeatMap = {
        songHeatmapList: undefined,
        songHeatmapListCompare: state.songHeatmap.songHeatmapDataCompare,
        isFetchingSongHeatmap: false,
        hasError: false,
        hasBannerWarning: false,
      };
      state.songHeatmap = newHeatMap;
    },
  },
});

export const {
  resetStateDashBoard,
  selectSong,
  setLoadToInactive,
  selectPeriod,
  clearSongSelection,
  setFilterSelection,
  clearHeatMapData,
  clearHeatMapDataComparison,
} = DashBoardSlice.actions;

export default DashBoardSlice.reducer;
