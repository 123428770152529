import { Button } from '@dsny/dsny-component-library';
import { device, mtrStyle } from 'src/styles/global';
import styled from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  isOpen: boolean;
}

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${theme.colors.white0};
  position: fixed;
  top: 0;
  width: 100%;
  padding: 16px 64px 16px 64px;
  z-index: 1001;

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const LogoWrapper = styled.div`
  svg {
    width: 153px;
    height: 54px;
  }

  @media ${device.mobile} {
    svg {
      width: 120px;
      height: 40px;
    }
  }
`;

export const Hamburguer = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 3px;
    width: 24px;
    background: ${theme.colors.mtr60};
    margin-bottom: 4px;
    border-radius: ${mtrStyle.borderRadius};
  }

  @media ${device.mobile} {
    display: flex;
  }
`;

export const Menu = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    overflow: ${({ isOpen }) => (isOpen ? '' : 'hidden')};
    max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
    transition: max-height 0.3s ease-in;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding-right: 0px;
    margin-top: 24px;
    gap: 24px;
  }
`;

export const SignoutButton = styled(Button)`
  width: 90px;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const LanguageMenu = styled.div`
  margin-top: 4px;
`;

export const StationListButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  gap: 8px;
`;

export const PromoPriceButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.mtr60};
  border: none;
  background: none;
  cursor: pointer;
  gap: 8px;
`;

export const PricingButton = styled.button`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  gap: 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
    height: 20px;
  }
`;
